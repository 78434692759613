// @flow
import * as React from 'react';
import size from 'lodash/fp/size';

import Modal from '@kwara/components/src/Modal';
import { useLoanProducts } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';
import { Stack } from '@kwara/components/src/Stack';
import { Text } from '@kwara/components/src/Intl';
import { ActionButton } from '@kwara/components/src/Button';
import { If } from '@kwara/components/src/If/If';

import { LoanProductSelector } from '../../components/ProductSelector';
import { EmptyModal } from '../../components/EmptyModal';
import { useMemberEligibility } from '../../models/request';
import { useStore } from '../../models/Member';

const LoanSelectContent = ({ products, eligibilities, filterInstantLoans }) => {
  const hasProducts = size(products) > 0;

  return (
    <If
      condition={!hasProducts && filterInstantLoans}
      do={
        <EmptyModal
          to={'/'}
          titleId={'InstantLoansList.Empty.Modal.title'}
          subtitleId={'InstantLoansList.Empty.Modal.subtitle'}
          actionId={'InstantLoansList.Empty.Modal.action'}
        />
      }
      else={
        <Modal isOpen={true}>
          <div className="flex items-center self-start mh2">
            <ActionButton to="/overview/loans" hideBorder col="black" type="back" />
            <h3 className="kw-weight-bold kw-text-x-large ml3 mb3">
              <Text id={`LoanAdd.Connect.${filterInstantLoans ? 'Instant' : ''}Product.title`} />
            </h3>
          </div>
          <div className={'flex flex-column h-100 overflow-scroll'}>
            <Stack.Child>
              <LoanProductSelector
                filterInstantLoans={filterInstantLoans}
                eligibilities={eligibilities}
                products={products}
              />
            </Stack.Child>
          </div>
        </Modal>
      }
    />
  );
};

export const LoanSelect = ({ filterInstantLoans = false }) => {
  const { member } = useStore();

  const r1 = useMemberEligibility(member.id);
  const r2 = useLoanProducts();

  return (
    <Loadable {...r1}>
      {eligibilities => (
        <Loadable {...r2}>
          {products => (
            <LoanSelectContent
              filterInstantLoans={filterInstantLoans}
              eligibilities={eligibilities}
              products={products.filter(p => p.activated)}
            />
          )}
        </Loadable>
      )}
    </Loadable>
  );
};
